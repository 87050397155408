import AlphaHelpers from '@nematis/wp-alpha/assets/js/abstracts/classes/alpha-helpers'

document.addEventListener('DOMContentLoaded', () => {
  // apply Bootstrap button classes to Gutenberg buttons
  const buttons = AlphaHelpers.smartQuerySelectorAll('.wp-block-button:not(.no-btn-styles)')
  const buttons100 = AlphaHelpers.smartQuerySelectorAll('.wp-block-button.btn-100')
  buttons.forEach((buttonWrapperEl) => {
    const btnStyle = buttonWrapperEl.classList.contains('secondary') ? 'secondary' : 'primary'
    const buttonLinkEl = buttonWrapperEl.querySelector('.wp-block-button__link')
    if (buttonLinkEl) {
      buttonLinkEl.classList.add('btn', `btn-${btnStyle}`)
    }
  })

  buttons100.forEach((buttonWrapperEl) => {
    const btnStyle = buttonWrapperEl.classList.contains('golden-dark') ? 'golden-dark' : '100'
    const buttonLinkEl = buttonWrapperEl.querySelector('.wp-block-button__link')
    if (buttonLinkEl) {
      buttonLinkEl.classList.add('btn', `btn-${btnStyle}`)
    }
  })
})
