document.addEventListener('show.bs.modal', () => {
// 60 secondes
  let countdown = 60
  const countdownElement = document.querySelector('.modal-countdown')
  const btnClose = document.querySelector('.exit-overlay-block .btn-close')

  // Countdown
  const updateCountdown = () => {
    countdownElement.textContent = countdown
    const percentage = countdown / 60 * 100
    countdownElement.style.setProperty('--percentage', `${percentage}%`)
    if (countdown > 0) {
      countdown--
    } else {
      clearInterval(countdownInterval)
      btnClose.click()
    }
  }

  // MAJ du countdown chaque seconde
  const countdownInterval = setInterval(updateCountdown, 1000)
})
